<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-shield"></i>
            {{ e("admins") }}
            -
            <b-link to="/admins/add" class="btn btn-primary">
              <i class="fa fa-user-plus"></i>
              {{ e("add") }}
            </b-link>
          </h4>
        </div>
        <div class="card-body">
          <div
            class="alert alert-warning text-center g"
            style="height: 150px"
            v-if="admins.length == 0 && !loading"
          >
            <br /><br />
            <h1><i class="fa fa-warning"></i></h1>
            <h4>{{ e("no-results") }}.</h4>
          </div>
          <div class="col-12 table-responsive" v-if="admins.length">
            <table class="table table-hover table-bordered table-striped">
              <thead>
                <th>
                  {{ e("name") }}
                </th>
                <th>
                  {{ e("username") }}
                </th>
                <th>
                  {{ e("options") }}
                </th>
              </thead>
              <tbody>
                <tr v-for="admin in admins" :key="admin._id">
                  <td>
                    {{ admin.name }}
                  </td>
                  <td>
                    {{ admin.username }}
                  </td>
                  <td>
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="e('options')"
                      variant="relief-secondary"
                    >
                      <b-dropdown-item @click="editUser(admin._id)">
                        <i class="fa fa-edit text-primary"></i>
                        {{ e("edit") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteUser(admin._id)"
                        ><i class="fa fa-trash text-danger"></i>
                        {{ e("delete") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BLink, BOverlay, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      admins: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/admins/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.admins = JSON.parse(r);
        g.loading = false;
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    editUser(id) {
      this.$router.push("/admins/edit/" + id);
    },
    deleteUser(id) {
      var g = this;
      if (confirm(e("confirm"))) {
        $.post(api + "/admins/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            location.reload();
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>